
import './galleries.scss';


$(document).ready(function () {

    // console.log('galleries');
    $('.gallery-preview').hide();

    $('.gallery-preview, .gallery-image').on('click', function (e) {

        let url = $(this).closest('.gallery').data('xurl');

        window.location.href = url;

    })

    $('.gallery-image').mouseenter(function (e) {

        let preview = $(this).parent().find('.gallery-preview');
        $(preview).fadeIn();

        if($(preview).children().length == 1){

            let url = $(preview).data('xurl');
            let prefix = $(preview).data('prefix');

            $.get(url, function (resp) {

                let html = "";

                let photos = resp;

                let max = 5;
                if(window.innerWidth <= 992){ //lg
                    max = 3;
                }

                for(var i = 0; i <= max; i++){
                    if(photos[i] != undefined){
                        let photo = photos[i];

                        html += "<div class='col-lg-4 col-6'>"
                        html += `<div class="photo" style="background-image: url('${prefix}/${photo.path}')"></div>`
                        html += "</div>"
                    }
                }

                $(preview).find('.spinner-border').hide();

                if($(preview).children().length == 1){
                    $(preview).append($(html));
                }

            });
        }

    });

    $('.gallery-preview').mouseleave(function (e) {

        let preview = $(this).parent().find('.gallery-preview');
        $(preview).fadeOut();

    });

})
